<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="monthCard-edit">
            <el-form :model="form" ref="form" :rules="rules" label-width="120px" label-position="left">
                <div class="a-fs-16 a-fw-700 a-flex-rfsc" style="margin-bottom: 16px">
                    <span>基本信息</span>
                </div>
                <el-form-item label="推送名称" prop="title">
                    <el-input class="inputBox" maxlength="20" clearable v-model="form.title" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="推送类型" prop="relationType">
                    <el-select v-model="form.relationType" placeholder="请选择推送类型" clearable @change='relationTypeChange'>
                        <el-option v-for="item in relationTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动ID" prop="relationId" v-if="form.relationType == 1" required>
                    <el-input class="inputBox" maxlength="16" clearable v-model="form.relationId" @blur='(e)=>{ handleGetRelationInfo(e, 1) }' placeholder="请输入关联ID"></el-input>
                    <div class="a-ml-10" v-if="form.relationName">活动名称：{{ form.relationName }}</div>
                </el-form-item>
                <el-form-item label="套餐ID" prop="relationId" v-if="form.relationType == 2">
                    <el-input class="inputBox" maxlength="16" clearable v-model="form.relationId" @blur='(e)=>{ handleGetRelationInfo(e, 2) }' placeholder="请输入关联ID"></el-input>
                    <div class="a-ml-10" v-if="form.relationName">套餐名称：{{ form.relationName }}</div>
                </el-form-item>
                <el-form-item label="推送海报" prop="poster">
                    <div class="a-flex-cfsfs">
                        <el-radio-group v-model="posterType" style="margin-bottom: 20px">
                            <el-radio :label="1" >网络图片</el-radio>
                            <el-radio :label="2" >本地图片</el-radio>
                        </el-radio-group>
                        <le-upload-file-img v-if="posterType == 1" notnull ref="icon" label="" :limit="1" v-model="form.poster"></le-upload-file-img>
                        <el-input v-if="posterType == 2" class="inputBox" clearable v-model="form.poster" placeholder="请输入本地图片地址"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="权重" prop="weight">
                    <jd-input-intnum class="inputBox" maxlength="4" clearable v-model="form.weight.weight" placeholder="请输入权重"></jd-input-intnum>
                    <div class="a-flex-cfsfs a-c-333 a-mt-10">
                        <span class="a-fw-700">权重减少规则：</span>
                        <span class="a-fs-12 a-c-error">权重减少为0后，不会推送。</span>
                    </div>
                    <div class="a-flex-rfsc a-mt-20">
                        <span>执行操作后减少</span>
                        <jd-input-intnum class="a-mlr-12" maxlength="4" clearable v-model="form.weight.executeReduceWeight" placeholder="请输入"></jd-input-intnum>
                        <span>权重</span>
                    </div>
                    <div class="a-flex-rfsc a-mt-10">
                        <span>查看后减少</span>
                        <jd-input-intnum class="a-mlr-12" maxlength="4" clearable v-model="form.weight.readReduceWeight" placeholder="请输入"></jd-input-intnum>
                        <span>权重</span>
                    </div>
                    <div class="a-flex-rfsc a-c-333 a-mt-20">
                        <span class="a-fw-700">权重恢复规则：</span>
                        <le-switch v-model="form.weight.returnRule" :acValue="1" :inacValue="0"></le-switch>
                    </div>
                    <div class="a-flex-rfsc a-mt-10" v-if="form.weight.returnRule">
                        <span>权重为</span>
                        <jd-input-intnum class="a-mlr-12" maxlength="4" clearable v-model="form.weight.limitWeight" placeholder="请输入"></jd-input-intnum>
                        <span>后，</span>
                        <jd-input-intnum class="a-mlr-12" maxlength="3" clearable v-model="form.weight.afterDay" placeholder="请输入"></jd-input-intnum>
                        <span>天恢复权重到</span>
                        <jd-input-intnum class="a-mlr-12" maxlength="4" clearable v-model="form.weight.returnWeight" placeholder="请输入"></jd-input-intnum>
                    </div>
                </el-form-item>
                <el-form-item label="不推送" prop="noPushType">
                    <el-checkbox v-model="form.noPushType" :true-label='1' :false-label='0' style="margin-right:0 !important">
                        <span>活动关联券没消耗完</span>
                    </el-checkbox>
                    <el-popover
                            placement="top-start"
                            title=""
                            width="400"
                            trigger="hover">
                            <div class="a-flex-cfsfs">
                                <span>1. 不管有没有资格参与 只要我有这个活动关联的有效的券我就不推送</span>
                                <span>2. 当我没有活动关联的券包的时候那么看资格 有资格就推送 无资格不推送</span>
                            </div>
                            <span slot="reference" class="el-icon-question a-c-666 a-fs-16" style="margin-right: 30px"></span>
                        </el-popover>
                    <el-checkbox checked disabled>
                        <span>没有资格的时候就不推送了</span>
                    </el-checkbox>
                    <el-popover
                        placement="top-start"
                        title=""
                        width="400"
                        trigger="hover">
                        <div class="a-flex-cfsfs">
                            <span>1. 不关心是否已购买过卷包 因为有些活动可以无限制购买次数。那么只判断是否还有购买资格 如果有资格就弹出推送 无资格不弹出推送</span>
                        </div>
                        <span slot="reference" class="el-icon-question a-c-666 a-fs-16"></span>
                    </el-popover>
                </el-form-item>
                <!-- <el-form-item label="可见范围" prop="relationId">
                    <el-radio-group v-model="radio">
                        <el-radio :label="1">跟随活动</el-radio>
                        <el-radio :label="2">自定义范围</el-radio>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item label="影响范围" v-if="form.relationType == 3">
                    <div class="a-flex-rfsc">
                        <el-select style="width: 190px" v-model="form.joinScopeType" placeholder="请选择">
                            <el-option label="全部" :value="1"></el-option>
                            <el-option label="指定" :value="2"></el-option>
                        </el-select>
                        <el-select style="width: 225px;margin-left: 10px" v-if="form.joinScopeType == 2" v-model="form.joinScopeTypeSecond" multiple placeholder="请选择指定范围（可多选）">
                            <el-option label="商户" :value="2"></el-option>
                            <el-option label="项目" :value="3"></el-option>
                            <el-option label="站点" :value="4"></el-option>
                        </el-select>
                    </div>
                    <le-company-under-select 
                        v-if="form.joinScopeTypeSecond.indexOf(2) != -1 && form.joinScopeType == 2"
                        label="商户"
                        placeholder="请选择商户（可多选）"
                        style="width:425px"
                        reserveKeyword 
                        :collapse='false'
                        multiple 
                        large
                        isObj
                        v-model="companyIds">
                    </le-company-under-select>
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeTypeSecond.indexOf(2) != -1 && form.joinScopeType == 2">共选择 {{ companyIds.length }} 商户</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeTypeSecond.indexOf(3) != -1 && form.joinScopeType == 2"
                        label="项目"
                        style="width:425px" 
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        :collapse='false'
                        v-model="incomeIds" 
                        :options="optionsincomeDistri" 
                        placeholder="请选择项目（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeTypeSecond.indexOf(3) != -1 && form.joinScopeType == 2">共选择 {{ incomeIds.length }} 个项目</span>
                    <le-select-remote-search 
                        v-if="form.joinScopeTypeSecond.indexOf(4) != -1 && form.joinScopeType == 2"
                        label="站点"
                        style="width:425px"
                        reserveKeyword 
                        multiple 
                        large
                        isObj
                        selectAll
                        :collapse='false'
                        v-model="stationIds" 
                        :options="optionsStation" placeholder="请选择站点（可多选）" />
                    <span class="a-fs-12 a-c-333 a-fw-700" v-if="form.joinScopeTypeSecond.indexOf(4) != -1 && form.joinScopeType == 2">共选择 {{ stationIds.length }} 个站点</span>
                </el-form-item>
                <el-form-item label="用户类型" prop="userType" v-if="form.relationType == 3">
                    <div class="a-flex-cfsfs">
                        <el-select v-model="form.userType" placeholder="请选择" clearable>
                            <el-option v-for="item in userTypeDic"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-input
                            v-if="form.userType == 3"
                            type="textarea"
                            :rows="4"
                            class="inputBox a-mt-10"
                            @blur='getAppointsUser'
                            placeholder="请输入指定用户手机号，使用“,”隔开"
                            v-model="appointUser">
                        </el-input>
                        <div class="a-flex-rfsc a-flex-wrap a-mt-10" v-if="form.userType == 3" style="width: 400px">
                            <el-tag
                                style="margin: 5px 10px 5px 0"
                                closable
                                @close='delAppointUser(index)'
                                v-for="(item,index) in appointUserInfoValidListOmit"
                                :key="index"
                                effect="dark">
                                {{ item.mobile }}
                            </el-tag>
                            <span 
                                class="font-point a-c-blue text-underline" 
                                @click="showAppointUserInfoValidList"
                                v-if="form.joinUserList.length > 10">
                                等{{ form.joinUserList.length }}个用户，点击查看全部用户</span>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="地区" prop="" v-if="form.relationType == 3">
                    <el-button type="primary" size='mini' @click="handleAddDistrict">添加地区</el-button>
                    <div class="a-flex-rfsc" v-for="(item, index) in districtList" :key="index">
                        <le-input-district-choose 
                            :ref="('districtChoose'+index)"
                            :province.sync="item.provinceCode" 
                            :city.sync="item.cityCode" 
                            :area.sync="item.districtCode"
                            large></le-input-district-choose>
                        <i class="a-c-blue el-icon-circle-plus-outline a-ml-20 a-fs-18" @click="handleAddDistrict(index)" v-if="index == 0"></i>
                        <i class="a-c-error el-icon-remove-outline a-ml-20 a-fs-18" @click="handleReduceDistrict(index)" v-if="districtList.length > 1"></i>
                    </div>
                </el-form-item>
                <el-form-item label="推送位置" prop="locationList">
                    <el-checkbox-group v-model="form.locationList">
                        <el-checkbox v-for="(item,index) in locationPathDic" :key="index" :label="item.value">{{ item.label }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="事件" prop="clickEvent" required>
                    <el-radio-group v-model="form.clickEvent" @change='clickEventChange'>
                        <el-radio :label="item.value" v-for="(item,index) in clickEventDic" :key="index">{{ item.label }}</el-radio>
                    </el-radio-group>
                    <div v-if="form.clickEvent == 2 || form.clickEvent == 3 || form.clickEvent == 4">
                        <el-select v-model="form.eventId" @change='clickParamChange' :placeholder="form.clickEvent == 2?'请选择跳转页面':'请选择执行操作'" clearable >
                            <el-option v-for="(item, index) in pushClickParamsDic"
                                :key="index"
                                :disabled='item.value == 4 && relationInfo.activityType != 4'
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="是否可关闭" prop="closeAble" required>
                    <el-radio-group v-model="form.closeAble">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="pushState" required>
                    <el-switch
                        v-model="form.pushState"
                        :inactive-value="0"
                        :active-value="1"
                        active-color="#F409EFF"
                        inactive-color="#56C6C">
                    </el-switch>
                    <span class="a-mlr-12" :class="form.pushState==1?'a-c-blue':'a-c-error'">{{ form.pushState?'已启用':'已停用' }}</span>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input
                        type="textarea"
                        :rows="5"
                        maxlength="200"
                        class="inputBox"
                        placeholder="请输入内容"
                        v-model="form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <div style="height: 100px;"></div>
            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancelSubmit">&nbsp;&nbsp;&nbsp;返回&nbsp;&nbsp;&nbsp;</el-button>
            </div>
        </el-card>
        <el-dialog title="指定用户" :visible.sync="dialogAppointUserVisible">
            <el-table :data="form.joinUserList" max-height='400px'>
                <el-table-column label="#" type="index" width="80"></el-table-column>
                <el-table-column prop="mobile" label="用户手机号" ></el-table-column>
                <el-table-column label="操作" width="80">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                            <img src="../../assets/icon/option-del-circle.png" class="a-wh-16" @click="delAppointUser(scope.$index)"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { number } from 'echarts';
import util from '../../../src/utils/util'
import jdInputIntnum from '../components/formcy/jd-input-intnum.vue';
export default {
  components: { jdInputIntnum },
    data() {
        var checkClickEvent = (rule, value, callback) => {
            if(this.form.clickEvent == '2' && !this.form.eventId){
                callback(new Error('请选择跳转页面'))
            } else if(this.form.clickEvent == '3' && !this.form.eventId){
                callback(new Error('请选择执行操作'))
            } else if(this.form.clickEvent == '4' && !this.form.eventId){
                callback(new Error('请选择执行操作'))
            } else {
                callback()
            }
        };
        var checkRelationId = (rule, value, callback) => {
            if(this.form.relationType == '1' && !this.form.relationId){
                callback(new Error('请输入活动ID'))
            } else {
                callback()
            }
        };
        var checkWeight = (rule, value, callback) => {
            if(!this.form.weight.weight){
                callback(new Error('请输入权重'))
            } else if(!this.form.weight.executeReduceWeight) {
                callback(new Error('请输入执行操作减少权重'))
            } else if(!this.form.weight.readReduceWeight) {
                callback(new Error('请输入查看减少权重'))
            } else if(this.form.weight.returnRule && !this.form.weight.limitWeight) {
                callback(new Error('请输入权重恢复规则中的权重最低值'))
            } else if(this.form.weight.returnRule && !this.form.weight.afterDay) {
                callback(new Error('请输入权重恢复规则中的恢复天数间隔'))
            } else if(this.form.weight.afterDay > 365 || this.form.weight.afterDay < 0) {
                callback(new Error('权重恢复规则中的恢复天数间隔只能输入0-365天'))
            } else if(this.form.weight.returnRule && !this.form.weight.returnWeight) {
                callback(new Error('请输入权重恢复规则中的权重恢复值'))
            } else {
                callback()
            }
        };
        
        return {
            id: '',
            util: util,
            dialogAppointUserVisible: false,
            form:{
                title: '',
                relationType: '',
                pushState: 1,
                relationId: '',
                relationName: '',
                clickEvent: '',
                poster: '',
                closeAble: 1,
                joinScopeType: 1,
                joinScopeTypeSecond: [],
                clickParam: '',
                eventId: '',
                userType: '',//用户类型
                joinUserList: [],
                locationList: [],
                noPushType: '',
                weight: {
                    afterDay: '3', //恢复天数
                    executeReduceWeight: '', //执行操作减少权重
                    limitWeight: '', //限制权重值
                    readReduceWeight: '', //查看减少权重
                    returnWeight: '', //恢复值
                    weight: '', //权重
                    returnRule: 1,//权重回复规则开关
                }
            },
            companyIds: [],
            incomeIds: [],
            stationIds: [],
            districtList: [],//地区
            appointUser: '',//指定用户
            optionsincomeDistri: {
                url: this.$Config.apiUrl.incomeDistriList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "name",
            },//项目数据
            optionsStation: {
                url: this.$Config.apiUrl.getStationList,
                method: "post",
                params: {
                    status: 2
                },
                showLoading: false,
                labelKey: "name",
                valueKey: "stationId",
                searchKey: "nameOrId",
            },//站点数据
            rules:{
                title: [{required: true, message:'请输入推送名称', trigger: 'blur'}],
                relationId: [{ validator: checkRelationId, trigger: 'blur' }],
                relationType: [{required: true, message:'请选择推送类型', trigger: 'change'}],
                poster: [{required: true, message:'请上传推送封面', trigger: 'change'}],
                userType: [{required: true, message:'请选择用户类型', trigger: 'change'}],
                clickEvent: [{ validator: checkClickEvent, trigger: 'change' }],
                weight: [{ validator: checkWeight, trigger: 'change' }],
                locationList: [{ type: 'array', required: true, message: '请至少选择一个推送位置', trigger: 'change' }],
            },
            relationTypeDic: [],
            userTypeDic: [],
            clickEventDic: [],
            pushClickParamsDic: [],
            provinceCode: '',
            cityCode: '',
            districtCode: '',
            locationPathDic: [],
            relationInfo: '',
            posterType: 1
        };
    },
    computed: {
        appointUserInfoValidListOmit () {
            if(this.form.joinUserList && this.form.joinUserList.length) {
                return this.form.joinUserList.slice(0,10)
            }else {
                return []
            }
        }
    },
    watch: {
        "form.relationId": {
            handler (val) {
                if(!val) {
                    this.form.relationName = ''
                }
            }
        }
    },
    created () {
        this.$getDic('adRelationType','select').then(res=>{ 
            this.relationTypeDic = res; 
            if(this.$route.query.activityId) {
                this.form.relationType = '1'
            }
        })
        this.$getDic('clickEvent','select').then(res=>{ this.clickEventDic = res; this.form.clickEvent = res[0].value })
        this.$getDic('activityUserType','select').then(res=>{ this.userTypeDic = res; })
        this.$getDic('locationPath','select').then(res=>{ this.locationPathDic = res; })
    },
    mounted () {
        this.id = this.$route.query.id?this.$route.query.id:''
        this.form.relationId = this.$route.query.activityId?this.$route.query.activityId:''
        if(this.$route.query.activityId) {
            this.handleGetRelationInfo('', 1)
        }
        if(this.id) {
            this.getPushInfo()
        }
    },
    methods: {
        getPushInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.pushInfo,
                method: "post",
                params: {
                    id: this.id
                }
            }).then(({data}) => {
                if(/https|http/.test(data.poster)) {
                    this.posterType == 1
                }else {
                    this.posterType == 2
                }
                if(data.adScopeList) {
                    this.companyIds = data.adScopeList.filter(item=>{
                        return item.scopeType == 1
                    }).map(item=>{
                        return {
                            label: item.companyName,
                            value: item.companyId
                        }
                    })

                    this.incomeIds = data.adScopeList.filter(item=>{
                        return item.scopeType == 2
                    }).map(item=>{
                        return {
                            label: item.incomeName,
                            value: item.incomeId
                        }
                    })

                    this.stationIds = data.adScopeList.filter(item=>{
                        return item.scopeType == 3
                    }).map(item=>{
                        return {
                            label: item.stationName,
                            value: item.stationId
                        }
                    })

                    this.districtList = data.adScopeList.filter(item=>{
                        return item.scopeType == 4
                    }).map(item=>{
                        return {
                            provinceCode: item.provinceCode,
                            cityCode: item.cityCode,
                            districtCode: item.districtCode
                        }
                    })
                }
                //省市区选择复显
                setTimeout(()=>{
                    this.districtList.map((item,index)=>{
                        if(item.provinceCode){
                            this.$refs[('districtChoose'+index)][0].values = [item.provinceCode]
                        }
                        if(item.cityCode){
                            this.$refs[('districtChoose'+index)][0].values = [item.provinceCode, item.cityCode]
                        }
                        if(item.districtCode){
                            this.$refs[('districtChoose'+index)][0].values = [item.provinceCode, item.cityCode, item.districtCode]
                        }
                        
                    })
                },200)

                let joinScopeTypeSecond = [] , joinScopeType = 1
                if(this.companyIds.length) {
                    joinScopeTypeSecond.push(2)
                    joinScopeType = 2
                }

                if(this.incomeIds.length) {
                    joinScopeTypeSecond.push(3)
                    joinScopeType = 2
                }

                if(this.stationIds.length) {
                    joinScopeTypeSecond.push(4)
                    joinScopeType = 2
                }

                let weight = {
                    ...data.weight,
                    afterDay: data.weight.afterDay ? JSON.stringify(data.weight.afterDay) : '', //恢复天数
                    executeReduceWeight: JSON.stringify(data.weight.executeReduceWeight), //执行操作减少权重
                    limitWeight: data.weight.limitWeight ? JSON.stringify(data.weight.limitWeight) : '', //限制权重值
                    readReduceWeight: JSON.stringify(data.weight.readReduceWeight), //查看减少权重
                    returnWeight: data.weight.returnWeight ? JSON.stringify(data.weight.returnWeight) : '', //恢复值
                    weight: JSON.stringify(data.weight.weight), //权重
                    returnRule: data.weight.afterDay || data.weight.limitWeight || data.weight.returnWeight ? 1 : 0
                }
                let locationList = data.locationList.map(item=>{
                    return JSON.stringify(item)
                })
                this.form = {
                    ...data,
                    joinScopeType: joinScopeType,
                    joinScopeTypeSecond: joinScopeTypeSecond,
                    relationType: JSON.stringify(data.relationType),
                    clickEvent: JSON.stringify(data.clickEvent),
                    userType: JSON.stringify(data.userType),
                    joinUserList: data.joinUserList ? data.joinUserList : [],
                    weight: weight,
                    locationList: locationList
                }
                this.getPushClickParams()
                if(this.form.relationId) {
                    this.handleGetRelationInfo('', this.form.relationType)
                }
            })
        },
        // 获取指定用户信息
        getAppointsUser () {
            let text = this.appointUser.replace(/，/g, ",")
            let arr = text.split(',')
            arr = arr.filter(item=>{
                const regExp = /^1[3-9]\d{9}$/;
                return regExp.test(item);
            })
            this.$Axios._post({
                url: this.$Config.apiUrl.getUserInfoByMobile,
                method: "post",
                params: {
                    mobiles: arr
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.form.joinUserList = res.result.data.map(item=>{
                        return {
                            mobile: item.mobile,
                            userId: item.userId
                        }
                    })
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 删除指定用户
        delAppointUser (index) {
            this.form.joinUserList.splice(index,1)
        },
        // 查看所有指定用户
        showAppointUserInfoValidList () {
            this.dialogAppointUserVisible = true
        },
        // 添加地区
        handleAddDistrict () {
            this.districtList.push({
                provinceCode: '',
                cityCode: '',
                districtCode: ''
            })
        },
        // 删除地区
        handleReduceDistrict (index) {
            this.districtList.splice(index,1)
        },
        clickEventChange () {
            this.form.clickParam = ''
            this.form.eventId = ''
            this.getPushClickParams()
        },
        clickParamChange () {
            this.form.clickParam = this.pushClickParamsDic.find(res=>{
                return res.id == this.form.eventId
            }).param
        },
        relationTypeChange () {
            this.form.relationId = ''
        },
        getPushClickParams () {
            let typeDic = {
                2: 1,
                3: 2,
                4: 2
            }
            this.$Axios._post({
                url: this.$Config.apiUrl.getPushClickParams,
                method: "post",
                showLoading: false,
                params: {
                    type: typeDic[this.form.clickEvent]
                }
            }).then(({data}) => {
                this.pushClickParamsDic = data.map(item=>{
                    return {
                        ...item,
                        label: item.name,
                        value: item.id
                    }
                })
            })
        },
        handleGetRelationInfo (e, type) {
            if(type == 1) {
                this.$Axios._post({
                    url: this.$Config.apiUrl.activityInfo,
                    showLoading: false,
                    params: {
                        id: this.form.relationId
                    }
                }).then(({data}) => {
                    if(data) {
                        this.form.relationName = data.activityTitle
                        this.relationInfo = data
                    }else {
                        this.form.relationId = ''
                        this.relationInfo = ''
                    }
                }).catch(_=>{
                    this.form.relationId = ''
                    this.relationInfo = ''
                })
            }else {
                this.$Axios._get({
                    url: this.$Config.apiUrl.monthCardDdetails,
                    showLoading: false,
                    params: {
                        id: this.form.relationId
                    }
                }).then(({data}) => {
                    if(data) {
                        this.form.relationName = data.name
                        this.relationInfo = data
                    }else {
                        this.form.relationId = ''
                        this.relationInfo = ''
                    }
                }).catch(_=>{
                    this.form.relationId = ''
                    this.relationInfo = ''
                })
            }
            
        },
        submit () {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$confirm('是否确认提交？','温馨提示')
                    .then(_=>{
                        let adScopeList = []
                        if(this.form.joinScopeTypeSecond.indexOf(2) != -1 && this.form.joinScopeType == 2) {
                            this.companyIds.map(item=>{
                                adScopeList.push({
                                    companyId: item.value,
                                    companyName: item.label,
                                    scopeType: 1
                                })
                            })
                        }

                        if(this.form.joinScopeTypeSecond.indexOf(3) != -1 && this.form.joinScopeType == 2) {
                            this.incomeIds.map(item=>{
                                adScopeList.push({
                                    incomeId: item.value,
                                    incomeName: item.label,
                                    scopeType: 2
                                })
                            })
                        }

                        if(this.form.joinScopeTypeSecond.indexOf(4) != -1 && this.form.joinScopeType == 2) {
                            this.stationIds.map(item=>{
                                adScopeList.push({
                                    stationId: item.value,
                                    stationName: item.label,
                                    scopeType: 3
                                })
                            })
                        }
                        
                        this.districtList.filter(item=>{
                            return item.provinceCode || item.cityCode || item.districtCode
                        }).map(item=>{
                            adScopeList.push({
                                provinceCode: item.provinceCode,
                                cityCode: item.cityCode,
                                districtCode: item.districtCode,
                                scopeType: 4
                            })
                        })

                        let weight = {
                            ...this.form.weight,
                            afterDay: this.form.weight.returnRule ? this.form.weight.afterDay : '',
                            limitWeight: this.form.weight.returnRule ? this.form.weight.limitWeight : '',
                            returnWeight: this.form.weight.returnRule ? this.form.weight.returnWeight : ''
                        }

                        let reqData = {
                            ...this.form,
                            weight: weight,
                            noPushType: this.form.noPushType ? 1 : 0,
                            adScopeList: adScopeList
                        }
                        this.$Axios._post({
                            url: this.$Config.apiUrl.pushEdit,
                            method: "post",
                            params: {
                                ...reqData
                            }
                        }).then(res => {
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.$store.dispatch("delView", this.$route);
                                this.$router.push({
                                    path: '/activity/push-list',
                                })
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                    })
                    .catch(err=>{
                        console.log(err);
                    })
                    
                }
            })
        },
       
        cancelSubmit () {
            this.$confirm('是否确认返回？','温馨提示')
            .then(_=>{
                this.$router.back()
                this.$store.dispatch("delView", this.$route);
            })
            .catch(_=>{})
        }
    },
}
</script>

<style lang="scss" scoped>
.monthCard-edit{
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    /deep/ .s-search-label {
		width: fit-content !important;
		justify-content: flex-start;
	}
    /deep/ .s-nomarl-input .el-input__inner {
		width: 200px;
	}
    /deep/ .date-range{
        margin: 0;
    }
    /deep/ .projectBox{
        &>div{
            margin: 0;
        }
    }
    /deep/ .el-form-item__content{
        width: fit-content;
    }
}
.inputBox{
    width: 400px;
}
.addBankBtn{
    padding: 0 20px;
    font-size: 14px;
    color: #007AFF;
    line-height: 34px;
    cursor: pointer;
}
.a-mb-10{
    margin-bottom: 10px;
}
</style>